import './App.css';

import React from 'react';
import { ResponsiveBar } from '@nivo/bar'

class App extends React.Component {
  constructor() {
    super();
    this.state = {
        data: []
    };
  }


  componentDidMount() {
    //fetch challenges
    fetch('https://netrom-xmas2021-dev.stage04.netromsoftware.ro/ranking')
        .then(response => response.json())
        .then(data => {
            const teams = []

            data.map((team, index) => {
                teams.push(team);
            })
            this.setState({
                data: teams
            })
        });
  }

  render() {
    return (

        <div style={{height: "3120px"}}>
            <div className="banner-image">
                <div className="banner-text">
                    <p style={{ "padding-left": 5, "font-size": 15, "font-weight": "bold"}}>Ultima actualizare: 2021-12-20 14:00</p>
                </div>
            </div>
              <ResponsiveBar
                        data={this.state.data}
                        layout="horizontal"
                        keys={['Friendly competition', 'Secret Santa', 'Santa renaissance', 'Year of Jolly', 'Kringle Doppelgänger', 'DJ Chriss', 'Wishlist', 'XMass Afterhours', 'Chill List', 'NRO Poets', 'XMass Spirit', 'Xmass Cosplay', 'The reenactment', 'Caroling around']}
                        indexBy="teamName"
                        margin={{ top: 80, right: 30, bottom: 70, left: 360 }}
                        padding={0.2}
                        valueScale={{ type: 'linear' }}
                        indexScale={{ type: 'band', round: true }}
                        colors={{ scheme: 'nivo' }}
                        defs={[
                            {
                                id: 'dots',
                                type: 'patternDots',
                                background: 'inherit',
                                color: '#e08a0b',
                                size: 5,
                                padding: 1,
                                stagger: true
                            },
                            {
                                id: 'lines',
                                type: 'patternLines',
                                background: 'inherit',
                                color: '#eed312',
                                rotation: -45,
                                lineWidth: 6,
                                spacing: 5
                            }
                        ]}
                        fill={[
                            {
                                match: {
                                    id: 'Friendly competition'
                                },
                                id: 'dots'
                            },
                            {
                                match: {
                                    id: 'Santa renaissance'
                                },
                                id: 'lines'
                            }
                        ]}
                        borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                        axisTop={{
                            tickRotation: 0,
                            tickPadding: 5,
                            tickSize: 15,
                            legend: 'Challenge points',
                            legendPosition: 'start',
                            legendOffset: -35,

                        }}
                        axisRight={null}
                        axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: 'Challenge points',
                            legendPosition: 'start',
                            legendOffset: 35,
                            legendSize: 50,
                        }}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 0,
                            tickRotation: 50,
                            legend: 'Teams',
                            legendPosition: 'end',
                            legendOffset: -100,
                            legendRotation: 5,

                            renderTick: (tick) => {
                                return (
                                    <g transform={`translate(${tick.x - 350},${tick.y - 140})`}>
                                        <g dangerouslySetInnerHTML={{__html: this.state.data[tick.tickIndex].icon}}></g>
                                            <text
                                                textAnchor="top"
                                                dominantBaseline="top"
                                                style={{
                                                    alignSelf: 'center', padding: 12,
                                                    width: 500,
                                                    fill: '#070404',
                                                    fontSize: 17,
                                                    fontWeight: 25,
                                                }}
                                            >

                                                {this.state.data[tick.tickIndex].teamName + ' - ' + this.state.data[tick.tickIndex].teamPoints}
                                            </text>
                                    </g>)},
                        }}

                        labelSkipWidth={10}
                        labelSkipHeight={10}
                        labelTextColor={{ from: 'color', modifiers: [ [ 'brighter', '3' ] ] }}
                        legends={[
                            {
                                dataFrom: 'keys',
                                anchor: 'bottom-right',
                                direction: 'row',
                                justify: false,
                                translateX: 0,
                                translateY: 71,
                                itemsSpacing: 32,
                                itemWidth: 100,
                                itemHeight: 20,
                                itemDirection: 'left-to-right',
                                itemOpacity: 0.85,
                                symbolSize: 20,
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemOpacity: 1
                                        }
                                    }
                                ]
                            }
                        ]}
                        role="application"
                        ariaLabel="Nivo bar chart demo"
                        barAriaLabel={function(e){return e.id+": "+e.formattedValue+" in country: "+e.indexValue}}
                    />
        </div>
    );
  }

}

export default App;
